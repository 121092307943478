import Vue from 'vue';
import Vuex from 'vuex';
import * as member from '../modules/member';
import * as generic from '../modules/generic';
import * as cm from '../modules/cm';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    member,
    generic,
    cm
  }
});

cm.init(store);

export default store;
