import Vue from 'vue';
import CommonPlugin from 'plugins/common-plugin';
import { i18n } from 'utils/i18n';
import 'styles/pages/page.css';
import store from 'stores/generic';

Vue.use(CommonPlugin);

new Vue({
  el: '#app',
  store,
  i18n
});
